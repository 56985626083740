@charset "UTF-8";
/*--------------------------------------------------------------------------------------------
*
*	Vars
*
*--------------------------------------------------------------------------------------------*/
/* colors */
/* acf-field */
/* responsive */
/*--------------------------------------------------------------------------------------------
*
*	ACF 6 ↓
*
*--------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------
*
*  Mixins
*
*--------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------
*
*  Global
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page #wpcontent {
  line-height: 140%;
}

/*---------------------------------------------------------------------------------------------
*
*  Links
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page a {
  color: #0783BE;
}

/*---------------------------------------------------------------------------------------------
*
*  Headings
*
*---------------------------------------------------------------------------------------------*/
.acf-h1, .acf-admin-page h1,
.acf-headerbar h1 {
  font-size: 21px;
  font-weight: 400;
}

.acf-h2, .acf-page-title, .acf-admin-page h2,
.acf-headerbar h2 {
  font-size: 18px;
  font-weight: 400;
}

.acf-h3, .acf-admin-page h3,
.acf-headerbar h3 {
  font-size: 16px;
  font-weight: 400;
}

/*---------------------------------------------------------------------------------------------
*
*  Paragraphs
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page .p1 {
  font-size: 15px;
}
.acf-admin-page .p2 {
  font-size: 14px;
}
.acf-admin-page .p3 {
  font-size: 13.5px;
}
.acf-admin-page .p4 {
  font-size: 13px;
}
.acf-admin-page .p5 {
  font-size: 12.5px;
}
.acf-admin-page .p6, .acf-admin-page .acf-field p.description, .acf-field .acf-admin-page p.description, .acf-admin-page .acf-small {
  font-size: 12px;
}
.acf-admin-page .p7, .acf-admin-page .acf-field-setting-prefix_label p.description code, .acf-field-setting-prefix_label p.description .acf-admin-page code,
.acf-admin-page .acf-field-setting-prefix_name p.description code,
.acf-field-setting-prefix_name p.description .acf-admin-page code {
  font-size: 11.5px;
}
.acf-admin-page .p8 {
  font-size: 11px;
}

/*---------------------------------------------------------------------------------------------
*
*  Page titles
*
*---------------------------------------------------------------------------------------------*/
.acf-page-title {
  color: #344054;
}

/*---------------------------------------------------------------------------------------------
*
*  Hide old / native WP titles from pages
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page .acf-settings-wrap h1 {
  display: none !important;
}
.acf-admin-page #acf-admin-tools h1:not(.acf-field-group-pro-features-title, .acf-field-group-pro-features-title-sm) {
  display: none !important;
}

/*---------------------------------------------------------------------------------------------
*
*  Small
*
*---------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------
*
*  Link focus style
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page a:focus {
  box-shadow: none;
  outline: none;
}
.acf-admin-page a:focus-visible {
  box-shadow: 0 0 0 1px #4f94d4, 0 0 2px 1px rgba(79, 148, 212, 0.8);
  outline: 1px solid transparent;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-field
*
*--------------------------------------------------------------------------------------------*/
.acf-field,
.acf-field .acf-label,
.acf-field .acf-input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.acf-field {
  margin: 15px 0;
  clear: both;
}
.acf-field p.description {
  display: block;
  margin: 0;
  padding: 0;
}
.acf-field .acf-label {
  vertical-align: top;
  margin: 0 0 10px;
}
.acf-field .acf-label label {
  display: block;
  font-weight: 500;
  margin: 0 0 3px;
  padding: 0;
}
.acf-field .acf-label:empty {
  margin-bottom: 0;
}
.acf-field .acf-input {
  vertical-align: top;
}
.acf-field p.description {
  display: block;
  margin-top: 6px;
  color: #667085;
}
.acf-field .acf-notice {
  margin: 0 0 15px;
  background: #edf2ff;
  color: #0c6ca0;
  border-color: #2183b9;
}
.acf-field .acf-notice.-error {
  background: #ffe6e6;
  color: #cc2727;
  border-color: #d12626;
}
.acf-field .acf-notice.-success {
  background: #eefbe8;
  color: #0e7b17;
  border-color: #32a23b;
}
.acf-field .acf-notice.-warning {
  background: #fff3e6;
  color: #bd4b0e;
  border-color: #d16226;
}
td.acf-field,
tr.acf-field {
  margin: 0;
}

.acf-field[data-width] {
  float: left;
  clear: none;
  /*
  	@media screen and (max-width: $sm) {
  		float: none;
  		width: auto;
  		border-left-width: 0;
  		border-right-width: 0;
  	}
  */
}
.acf-field[data-width] + .acf-field[data-width] {
  border-left: 1px solid #eeeeee;
}
html[dir=rtl] .acf-field[data-width] {
  float: right;
}
html[dir=rtl] .acf-field[data-width] + .acf-field[data-width] {
  border-left: none;
  border-right: 1px solid #eeeeee;
}
td.acf-field[data-width],
tr.acf-field[data-width] {
  float: none;
}

.acf-field.-c0 {
  clear: both;
  border-left-width: 0 !important;
}
html[dir=rtl] .acf-field.-c0 {
  border-left-width: 1px !important;
  border-right-width: 0 !important;
}

.acf-field.-r0 {
  border-top-width: 0 !important;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-fields
*
*--------------------------------------------------------------------------------------------*/
.acf-fields {
  position: relative;
}
.acf-fields:after {
  display: block;
  clear: both;
  content: "";
}
.acf-fields.-border {
  border: #ccd0d4 solid 1px;
  background: #fff;
}
.acf-fields > .acf-field {
  position: relative;
  margin: 0;
  padding: 16px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #EAECF0;
}
.acf-fields > .acf-field:first-child {
  border-top: none;
  margin-top: 0;
}
td.acf-fields {
  padding: 0 !important;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-fields (clear)
*
*--------------------------------------------------------------------------------------------*/
.acf-fields.-clear > .acf-field {
  border: none;
  padding: 0;
  margin: 15px 0;
}
.acf-fields.-clear > .acf-field[data-width] {
  border: none !important;
}
.acf-fields.-clear > .acf-field > .acf-label {
  padding: 0;
}
.acf-fields.-clear > .acf-field > .acf-input {
  padding: 0;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-fields (left)
*
*--------------------------------------------------------------------------------------------*/
.acf-fields.-left > .acf-field {
  padding: 15px 0;
}
.acf-fields.-left > .acf-field:after {
  display: block;
  clear: both;
  content: "";
}
.acf-fields.-left > .acf-field:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  background: #f9f9f9;
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 0 1px 0 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20%;
}
.acf-fields.-left > .acf-field[data-width] {
  float: none;
  width: auto !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}
.acf-fields.-left > .acf-field > .acf-label {
  float: left;
  width: 20%;
  margin: 0;
  padding: 0 12px;
}
.acf-fields.-left > .acf-field > .acf-input {
  float: left;
  width: 80%;
  margin: 0;
  padding: 0 12px;
}
html[dir=rtl] .acf-fields.-left > .acf-field:before {
  border-width: 0 0 0 1px;
  left: auto;
  right: 0;
}
html[dir=rtl] .acf-fields.-left > .acf-field > .acf-label {
  float: right;
}
html[dir=rtl] .acf-fields.-left > .acf-field > .acf-input {
  float: right;
}
#side-sortables .acf-fields.-left > .acf-field:before {
  display: none;
}
#side-sortables .acf-fields.-left > .acf-field > .acf-label {
  width: 100%;
  margin-bottom: 10px;
}
#side-sortables .acf-fields.-left > .acf-field > .acf-input {
  width: 100%;
}
@media screen and (max-width: 640px) {
  .acf-fields.-left > .acf-field:before {
    display: none;
  }
  .acf-fields.-left > .acf-field > .acf-label {
    width: 100%;
    margin-bottom: 10px;
  }
  .acf-fields.-left > .acf-field > .acf-input {
    width: 100%;
  }
}

/* clear + left */
.acf-fields.-clear.-left > .acf-field {
  padding: 0;
  border: none;
}
.acf-fields.-clear.-left > .acf-field:before {
  display: none;
}
.acf-fields.-clear.-left > .acf-field > .acf-label {
  padding: 0;
}
.acf-fields.-clear.-left > .acf-field > .acf-input {
  padding: 0;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-table
*
*--------------------------------------------------------------------------------------------*/
.acf-table tr.acf-field > td.acf-label {
  padding: 15px 12px;
  margin: 0;
  background: #f9f9f9;
  width: 20%;
}
.acf-table tr.acf-field > td.acf-input {
  padding: 15px 12px;
  margin: 0;
  border-left-color: #e1e1e1;
}

.acf-sortable-tr-helper {
  position: relative !important;
  display: table-row !important;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-postbox
*
*--------------------------------------------------------------------------------------------*/
.acf-postbox {
  position: relative;
}
.acf-postbox > .inside {
  margin: 0 !important; /* override WP style - do not delete - you have tried this before */
  padding: 0 !important; /* override WP style - do not delete - you have tried this before */
}
.acf-postbox .acf-hndle-cog {
  color: #72777c;
  font-size: 16px;
  line-height: 36px;
  height: 36px;
  width: 1.62rem;
  position: relative;
  display: none;
}
.acf-postbox .acf-hndle-cog:hover {
  color: #191e23;
}
.acf-postbox > .hndle:hover .acf-hndle-cog,
.acf-postbox > .postbox-header:hover .acf-hndle-cog {
  display: inline-block;
}
.acf-postbox > .hndle .acf-hndle-cog {
  height: 20px;
  line-height: 20px;
  float: right;
  width: auto;
}
.acf-postbox > .hndle .acf-hndle-cog:hover {
  color: #777777;
}
.acf-postbox .acf-replace-with-fields {
  padding: 15px;
  text-align: center;
}

#post-body-content #acf_after_title-sortables {
  margin: 20px 0 -20px;
}

/* seamless */
.acf-postbox.seamless {
  border: 0 none;
  background: transparent;
  box-shadow: none;
  /* hide hndle */
  /* inside */
}
.acf-postbox.seamless > .postbox-header,
.acf-postbox.seamless > .hndle,
.acf-postbox.seamless > .handlediv {
  display: none !important;
}
.acf-postbox.seamless > .inside {
  display: block !important; /* stop metabox from hiding when closed */
  margin-left: -12px !important;
  margin-right: -12px !important;
}
.acf-postbox.seamless > .inside > .acf-field {
  border-color: transparent;
}

/* seamless (left) */
.acf-postbox.seamless > .acf-fields.-left {
  /* hide sidebar bg */
  /* mobile */
}
.acf-postbox.seamless > .acf-fields.-left > .acf-field:before {
  display: none;
}
@media screen and (max-width: 782px) {
  .acf-postbox.seamless > .acf-fields.-left {
    /* remove padding */
  }
  .acf-postbox.seamless > .acf-fields.-left > .acf-field > .acf-label, .acf-postbox.seamless > .acf-fields.-left > .acf-field > .acf-input {
    padding: 0;
  }
}

/*-----------------------------------------------------------------------------
*
*  Inputs
*
*-----------------------------------------------------------------------------*/
.acf-field input[type=text],
.acf-field input[type=password],
.acf-field input[type=date],
.acf-field input[type=datetime],
.acf-field input[type=datetime-local],
.acf-field input[type=email],
.acf-field input[type=month],
.acf-field input[type=number],
.acf-field input[type=search],
.acf-field input[type=tel],
.acf-field input[type=time],
.acf-field input[type=url],
.acf-field input[type=week],
.acf-field textarea,
.acf-field select {
  width: 100%;
  padding: 4px 8px;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.4;
}
.acf-admin-3-8 .acf-field input[type=text],
.acf-admin-3-8 .acf-field input[type=password],
.acf-admin-3-8 .acf-field input[type=date],
.acf-admin-3-8 .acf-field input[type=datetime],
.acf-admin-3-8 .acf-field input[type=datetime-local],
.acf-admin-3-8 .acf-field input[type=email],
.acf-admin-3-8 .acf-field input[type=month],
.acf-admin-3-8 .acf-field input[type=number],
.acf-admin-3-8 .acf-field input[type=search],
.acf-admin-3-8 .acf-field input[type=tel],
.acf-admin-3-8 .acf-field input[type=time],
.acf-admin-3-8 .acf-field input[type=url],
.acf-admin-3-8 .acf-field input[type=week],
.acf-admin-3-8 .acf-field textarea,
.acf-admin-3-8 .acf-field select {
  padding: 3px 5px;
}
.acf-field textarea {
  resize: vertical;
}

body.acf-browser-firefox .acf-field select {
  padding: 4px 5px;
}

/*-----------------------------------------------------------------------------
*
*  Text
*
*-----------------------------------------------------------------------------*/
.acf-input-prepend,
.acf-input-append,
.acf-input-wrap {
  box-sizing: border-box;
}

.acf-input-prepend,
.acf-input-append {
  font-size: 13px;
  line-height: 1.4;
  padding: 4px 8px;
  background: #f5f5f5;
  border: #7e8993 solid 1px;
  min-height: 30px;
}
.acf-admin-3-8 .acf-input-prepend,
.acf-admin-3-8 .acf-input-append {
  padding: 3px 5px;
  border-color: #dddddd;
  min-height: 28px;
}

.acf-input-prepend {
  float: left;
  border-right-width: 0;
  border-radius: 3px 0 0 3px;
}

.acf-input-append {
  float: right;
  border-left-width: 0;
  border-radius: 0 3px 3px 0;
}

.acf-input-wrap {
  position: relative;
  overflow: hidden;
}
.acf-input-wrap .acf-is-prepended {
  border-radius: 0 6px 6px 0 !important;
}
.acf-input-wrap .acf-is-appended {
  border-radius: 6px 0 0 6px !important;
}
.acf-input-wrap .acf-is-prepended.acf-is-appended {
  border-radius: 0 !important;
}

/* rtl */
html[dir=rtl] .acf-input-prepend {
  border-left-width: 0;
  border-right-width: 1px;
  border-radius: 0 3px 3px 0;
  float: right;
}

html[dir=rtl] .acf-input-append {
  border-left-width: 1px;
  border-right-width: 0;
  border-radius: 3px 0 0 3px;
  float: left;
}

html[dir=rtl] input.acf-is-prepended {
  border-radius: 3px 0 0 3px !important;
}

html[dir=rtl] input.acf-is-appended {
  border-radius: 0 3px 3px 0 !important;
}

html[dir=rtl] input.acf-is-prepended.acf-is-appended {
  border-radius: 0 !important;
}

/*-----------------------------------------------------------------------------
*
*  Color Picker
*
*-----------------------------------------------------------------------------*/
.acf-color-picker .wp-color-result {
  border-color: #7e8993;
}
.acf-admin-3-8 .acf-color-picker .wp-color-result {
  border-color: #ccd0d4;
}
.acf-color-picker .wp-picker-active {
  position: relative;
  z-index: 1;
}

/*-----------------------------------------------------------------------------
*
*  Url
*
*-----------------------------------------------------------------------------*/
.acf-url i {
  position: absolute;
  top: 5px;
  left: 5px;
  opacity: 0.5;
  color: #7e8993;
}
.acf-url input[type=url] {
  padding-left: 27px !important;
}
.acf-url.-valid i {
  opacity: 1;
}

/*-----------------------------------------------------------------------------
*
*  Select2 (v3)
*
*-----------------------------------------------------------------------------*/
.select2-container.-acf {
  z-index: 1001;
  /* open */
  /* single open */
}
.select2-container.-acf .select2-choices {
  background: #fff;
  border-color: #ddd;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07) inset;
  min-height: 31px;
}
.select2-container.-acf .select2-choices .select2-search-choice {
  margin: 5px 0 5px 5px;
  padding: 3px 5px 3px 18px;
  border-color: #bbb;
  background: #f9f9f9;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25) inset;
  /* sortable item*/
  /* sortable shadow */
}
.select2-container.-acf .select2-choices .select2-search-choice.ui-sortable-helper {
  background: #5897fb;
  border-color: #3f87fa;
  color: #fff !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}
.select2-container.-acf .select2-choices .select2-search-choice.ui-sortable-helper a {
  visibility: hidden;
}
.select2-container.-acf .select2-choices .select2-search-choice.ui-sortable-placeholder {
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  visibility: visible !important;
}
.select2-container.-acf .select2-choices .select2-search-choice-focus {
  border-color: #999;
}
.select2-container.-acf .select2-choices .select2-search-field input {
  height: 31px;
  line-height: 22px;
  margin: 0;
  padding: 5px 5px 5px 7px;
}
.select2-container.-acf .select2-choice {
  border-color: #bbbbbb;
}
.select2-container.-acf .select2-choice .select2-arrow {
  background: transparent;
  border-left-color: #dfdfdf;
  padding-left: 1px;
}
.select2-container.-acf .select2-choice .select2-result-description {
  display: none;
}
.select2-container.-acf.select2-container-active .select2-choices, .select2-container.-acf.select2-dropdown-open .select2-choices {
  border-color: #5b9dd9;
  border-radius: 3px 3px 0 0;
}
.select2-container.-acf.select2-dropdown-open .select2-choice {
  background: #fff;
  border-color: #5b9dd9;
}

/* rtl */
html[dir=rtl] .select2-container.-acf .select2-search-choice-close {
  left: 24px;
}
html[dir=rtl] .select2-container.-acf .select2-choice > .select2-chosen {
  margin-left: 42px;
}
html[dir=rtl] .select2-container.-acf .select2-choice .select2-arrow {
  padding-left: 0;
  padding-right: 1px;
}

/* description */
.select2-drop {
  /* search*/
  /* result */
}
.select2-drop .select2-search {
  padding: 4px 4px 0;
}
.select2-drop .select2-result {
  /* hover*/
}
.select2-drop .select2-result .select2-result-description {
  color: #999;
  font-size: 12px;
  margin-left: 5px;
}
.select2-drop .select2-result.select2-highlighted .select2-result-description {
  color: #fff;
  opacity: 0.75;
}

/*-----------------------------------------------------------------------------
*
*  Select2 (v4)
*
*-----------------------------------------------------------------------------*/
.select2-container.-acf li {
  margin-bottom: 0;
}
.select2-container.-acf[data-select2-id^=select2-data] .select2-selection--multiple {
  overflow: hidden;
}
.select2-container.-acf .select2-selection {
  border-color: #7e8993;
}
.acf-admin-3-8 .select2-container.-acf .select2-selection {
  border-color: #aaa;
}
.select2-container.-acf .select2-selection--multiple .select2-search--inline:first-child {
  float: none;
}
.select2-container.-acf .select2-selection--multiple .select2-search--inline:first-child input {
  width: 100% !important;
}
.select2-container.-acf .select2-selection--multiple .select2-selection__rendered {
  padding-right: 0;
}
.select2-container.-acf .select2-selection--multiple .select2-selection__rendered[id^=select2-acf-field] {
  display: inline;
  padding: 0;
  margin: 0;
}
.select2-container.-acf .select2-selection--multiple .select2-selection__rendered[id^=select2-acf-field] .select2-selection__choice {
  margin-right: 0;
}
.select2-container.-acf .select2-selection--multiple .select2-selection__choice {
  background-color: #f7f7f7;
  border-color: #cccccc;
  max-width: 100%;
  overflow: hidden;
  word-wrap: normal !important;
  white-space: normal;
}
.select2-container.-acf .select2-selection--multiple .select2-selection__choice.ui-sortable-helper {
  background: #0783BE;
  border-color: #066998;
  color: #fff !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}
.select2-container.-acf .select2-selection--multiple .select2-selection__choice.ui-sortable-helper span {
  visibility: hidden;
}
.select2-container.-acf .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  position: static;
  border-right: none;
  padding: 0;
}
.select2-container.-acf .select2-selection--multiple .select2-selection__choice.ui-sortable-placeholder {
  background-color: #F2F4F7;
  border-color: #F2F4F7;
  visibility: visible !important;
}
.select2-container.-acf .select2-selection--multiple .select2-search__field {
  box-shadow: none !important;
  min-height: 0;
}
.acf-row .select2-container.-acf .select2-selection--single {
  overflow: hidden;
}
.acf-row .select2-container.-acf .select2-selection--single .select2-selection__rendered {
  white-space: normal;
}

.acf-admin-single-field-group .select2-dropdown {
  border-color: #6BB5D8 !important;
  margin-top: -5px;
  overflow: hidden;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
}

.select2-dropdown.select2-dropdown--above {
  margin-top: 0;
}

.acf-admin-single-field-group .select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #F9FAFB !important;
  color: #667085;
}
.acf-admin-single-field-group .select2-container--default .select2-results__option[aria-selected=true]:hover {
  color: #399CCB;
}

.acf-admin-single-field-group .select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: #fff !important;
  background-color: #0783BE !important;
}

.select2-dropdown .select2-results__option {
  margin-bottom: 0;
}

.select2-container .select2-dropdown {
  z-index: 900000;
}
.select2-container .select2-dropdown .select2-search__field {
  line-height: 1.4;
  min-height: 0;
}

/*-----------------------------------------------------------------------------
*
*  Link
*
*-----------------------------------------------------------------------------*/
.acf-link .link-wrap {
  display: none;
  border: #ccd0d4 solid 1px;
  border-radius: 3px;
  padding: 5px;
  line-height: 26px;
  background: #fff;
  word-wrap: break-word;
  word-break: break-all;
}
.acf-link .link-wrap .link-title {
  padding: 0 5px;
}
.acf-link.-value .button {
  display: none;
}
.acf-link.-value .acf-icon.-link-ext {
  display: none;
}
.acf-link.-value .link-wrap {
  display: inline-block;
}
.acf-link.-external .acf-icon.-link-ext {
  display: inline-block;
}

#wp-link-backdrop {
  z-index: 900000 !important;
}

#wp-link-wrap {
  z-index: 900001 !important;
}

/*-----------------------------------------------------------------------------
*
*  Radio
*
*-----------------------------------------------------------------------------*/
ul.acf-radio-list,
ul.acf-checkbox-list {
  background: transparent;
  border: 1px solid transparent;
  position: relative;
  padding: 1px;
  margin: 0;
  /* hl */
  /* rtl */
}
ul.acf-radio-list:focus-within,
ul.acf-checkbox-list:focus-within {
  border: 1px solid #A5D2E7;
  border-radius: 6px;
}
ul.acf-radio-list li,
ul.acf-checkbox-list li {
  font-size: 13px;
  line-height: 22px;
  margin: 0;
  position: relative;
  word-wrap: break-word;
  /* attachment sidebar fix*/
}
ul.acf-radio-list li label,
ul.acf-checkbox-list li label {
  display: inline;
}
ul.acf-radio-list li input[type=checkbox],
ul.acf-radio-list li input[type=radio],
ul.acf-checkbox-list li input[type=checkbox],
ul.acf-checkbox-list li input[type=radio] {
  margin: -1px 4px 0 0;
  vertical-align: middle;
}
ul.acf-radio-list li input[type=text],
ul.acf-checkbox-list li input[type=text] {
  width: auto;
  vertical-align: middle;
  margin: 2px 0;
}
ul.acf-radio-list li span,
ul.acf-checkbox-list li span {
  float: none;
}
ul.acf-radio-list li i,
ul.acf-checkbox-list li i {
  vertical-align: middle;
}
ul.acf-radio-list.acf-hl li,
ul.acf-checkbox-list.acf-hl li {
  margin-right: 20px;
  clear: none;
}
html[dir=rtl] ul.acf-radio-list input[type=checkbox],
html[dir=rtl] ul.acf-radio-list input[type=radio],
html[dir=rtl] ul.acf-checkbox-list input[type=checkbox],
html[dir=rtl] ul.acf-checkbox-list input[type=radio] {
  margin-left: 4px;
  margin-right: 0;
}

/*-----------------------------------------------------------------------------
*
*  Button Group
*
*-----------------------------------------------------------------------------*/
.acf-button-group {
  display: inline-block;
  /* default (horizontal) */
  /* vertical */
}
.acf-button-group label {
  display: inline-block;
  border: #7e8993 solid 1px;
  position: relative;
  z-index: 1;
  padding: 5px 10px;
  background: #fff;
}
.acf-button-group label:hover {
  color: #016087;
  background: #f3f5f6;
  border-color: #0071a1;
  z-index: 2;
}
.acf-button-group label.selected {
  border-color: #007cba;
  background: #008dd4;
  color: #fff;
  z-index: 2;
}
.acf-button-group input {
  display: none !important;
}
.acf-button-group {
  padding-left: 1px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.acf-button-group label {
  margin: 0 0 0 -1px;
  flex: 1;
  text-align: center;
  white-space: nowrap;
}
.acf-button-group label:first-child {
  border-radius: 3px 0 0 3px;
}
html[dir=rtl] .acf-button-group label:first-child {
  border-radius: 0 3px 3px 0;
}
.acf-button-group label:last-child {
  border-radius: 0 3px 3px 0;
}
html[dir=rtl] .acf-button-group label:last-child {
  border-radius: 3px 0 0 3px;
}
.acf-button-group label:only-child {
  border-radius: 3px;
}
.acf-button-group.-vertical {
  padding-left: 0;
  padding-top: 1px;
  flex-direction: column;
}
.acf-button-group.-vertical label {
  margin: -1px 0 0 0;
}
.acf-button-group.-vertical label:first-child {
  border-radius: 3px 3px 0 0;
}
.acf-button-group.-vertical label:last-child {
  border-radius: 0 0 3px 3px;
}
.acf-button-group.-vertical label:only-child {
  border-radius: 3px;
}
.acf-admin-3-8 .acf-button-group label {
  border-color: #ccd0d4;
}
.acf-admin-3-8 .acf-button-group label:hover {
  border-color: #0071a1;
}
.acf-admin-3-8 .acf-button-group label.selected {
  border-color: #007cba;
}

.acf-admin-page .acf-button-group {
  display: flex;
  align-items: stretch;
  align-content: center;
  height: 40px;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
}
.acf-admin-page .acf-button-group label {
  display: inline-flex;
  align-items: center;
  align-content: center;
  border: #D0D5DD solid 1px;
  padding: 6px 16px;
  color: #475467;
  font-weight: 500;
}
.acf-admin-page .acf-button-group label:hover {
  color: #0783BE;
}
.acf-admin-page .acf-button-group label.selected {
  background: #F9FAFB;
  color: #0783BE;
}
.acf-admin-page .select2-container.-acf .select2-selection--multiple .select2-selection__choice {
  display: inline-flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 2px;
  position: relative;
  padding-top: 4px;
  padding-right: auto;
  padding-bottom: 4px;
  padding-left: 8px;
  background-color: #EBF5FA;
  border-color: #A5D2E7;
  color: #0783BE;
}
.acf-admin-page .select2-container.-acf .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  order: 2;
  width: 14px;
  height: 14px;
  margin-right: 0;
  margin-left: 4px;
  color: #6BB5D8;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.acf-admin-page .select2-container.-acf .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:hover {
  color: #0783BE;
}
.acf-admin-page .select2-container.-acf .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  top: 0;
  left: 0;
  background-color: currentColor;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url("../../images/icons/icon-close.svg");
  mask-image: url("../../images/icons/icon-close.svg");
}

/*-----------------------------------------------------------------------------
*
*  Checkbox
*
*-----------------------------------------------------------------------------*/
.acf-checkbox-list .button {
  margin: 10px 0 0;
}

/*-----------------------------------------------------------------------------
*
*  True / False
*
*-----------------------------------------------------------------------------*/
.acf-switch {
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  background: #f5f5f5;
  height: 30px;
  vertical-align: middle;
  border: #7e8993 solid 1px;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -o-transition: background 0.25s ease;
  transition: background 0.25s ease;
  /* hover */
  /* active */
  /* message */
}
.acf-switch span {
  display: inline-block;
  float: left;
  text-align: center;
  font-size: 13px;
  line-height: 22px;
  padding: 4px 10px;
  min-width: 15px;
}
.acf-switch span i {
  vertical-align: middle;
}
.acf-switch .acf-switch-on {
  color: #fff;
  text-shadow: #007cba 0 1px 0;
}
.acf-switch .acf-switch-slider {
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 50%;
  z-index: 1;
  background: #fff;
  border-radius: 3px;
  border: #7e8993 solid 1px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  transition-property: left, right;
}
.acf-switch:hover, .acf-switch.-focus {
  border-color: #0071a1;
  background: #f3f5f6;
  color: #016087;
}
.acf-switch:hover .acf-switch-slider, .acf-switch.-focus .acf-switch-slider {
  border-color: #0071a1;
}
.acf-switch.-on {
  background: #0d99d5;
  border-color: #007cba;
  /* hover */
}
.acf-switch.-on .acf-switch-slider {
  left: 50%;
  right: 2px;
  border-color: #007cba;
}
.acf-switch.-on:hover {
  border-color: #007cba;
}
.acf-switch + span {
  margin-left: 6px;
}
.acf-admin-3-8 .acf-switch {
  border-color: #ccd0d4;
}
.acf-admin-3-8 .acf-switch .acf-switch-slider {
  border-color: #ccd0d4;
}
.acf-admin-3-8 .acf-switch:hover, .acf-admin-3-8 .acf-switch.-focus {
  border-color: #0071a1;
}
.acf-admin-3-8 .acf-switch:hover .acf-switch-slider, .acf-admin-3-8 .acf-switch.-focus .acf-switch-slider {
  border-color: #0071a1;
}
.acf-admin-3-8 .acf-switch.-on {
  border-color: #007cba;
}
.acf-admin-3-8 .acf-switch.-on .acf-switch-slider {
  border-color: #007cba;
}
.acf-admin-3-8 .acf-switch.-on:hover {
  border-color: #007cba;
}

/* checkbox */
.acf-switch-input {
  opacity: 0;
  position: absolute;
  margin: 0;
}

.acf-admin-single-field-group .acf-true-false {
  border: 1px solid transparent;
}
.acf-admin-single-field-group .acf-true-false:focus-within {
  border: 1px solid #399CCB;
  border-radius: 120px;
}

/* in media modal */
.compat-item .acf-true-false .message {
  float: none;
  padding: 0;
  vertical-align: middle;
}

/*--------------------------------------------------------------------------
*
*	Google Map
*
*-------------------------------------------------------------------------*/
.acf-google-map {
  position: relative;
  border: #ccd0d4 solid 1px;
  background: #fff;
}
.acf-google-map .title {
  position: relative;
  border-bottom: #ccd0d4 solid 1px;
}
.acf-google-map .title .search {
  margin: 0;
  font-size: 14px;
  line-height: 30px;
  height: 40px;
  padding: 5px 10px;
  border: 0 none;
  box-shadow: none;
  border-radius: 0;
  font-family: inherit;
  cursor: text;
}
.acf-google-map .title .acf-loading {
  position: absolute;
  top: 10px;
  right: 11px;
  display: none;
}
.acf-google-map .title .acf-icon:active {
  display: inline-block !important;
}
.acf-google-map .canvas {
  height: 400px;
}
.acf-google-map:hover .title .acf-actions {
  display: block;
}
.acf-google-map .title .acf-icon.-location {
  display: inline-block;
}
.acf-google-map .title .acf-icon.-cancel,
.acf-google-map .title .acf-icon.-search {
  display: none;
}
.acf-google-map.-value .title .search {
  font-weight: bold;
}
.acf-google-map.-value .title .acf-icon.-location {
  display: none;
}
.acf-google-map.-value .title .acf-icon.-cancel {
  display: inline-block;
}
.acf-google-map.-searching .title .acf-icon.-location {
  display: none;
}
.acf-google-map.-searching .title .acf-icon.-cancel,
.acf-google-map.-searching .title .acf-icon.-search {
  display: inline-block;
}
.acf-google-map.-searching .title .acf-actions {
  display: block;
}
.acf-google-map.-searching .title .search {
  font-weight: normal !important;
}
.acf-google-map.-loading .title a {
  display: none !important;
}
.acf-google-map.-loading .title i {
  display: inline-block;
}

/* autocomplete */
.pac-container {
  border-width: 1px 0;
  box-shadow: none;
}

.pac-container:after {
  display: none;
}

.pac-container .pac-item:first-child {
  border-top: 0 none;
}

.pac-container .pac-item {
  padding: 5px 10px;
  cursor: pointer;
}

html[dir=rtl] .pac-container .pac-item {
  text-align: right;
}

/*--------------------------------------------------------------------------
*
*	Relationship
*
*-------------------------------------------------------------------------*/
.acf-relationship {
  background: #fff;
  border: #ccd0d4 solid 1px;
  /* list */
  /* selection (bottom) */
}
.acf-relationship .filters {
  border-bottom: #ccd0d4 solid 1px;
  background: #fff;
  /* widths */
}
.acf-relationship .filters:after {
  display: block;
  clear: both;
  content: "";
}
.acf-relationship .filters .filter {
  margin: 0;
  padding: 0;
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 7px 7px 7px 0;
}
.acf-relationship .filters .filter:first-child {
  padding-left: 7px;
}
.acf-relationship .filters .filter input,
.acf-relationship .filters .filter select {
  margin: 0;
  float: none; /* potential fix for media popup? */
}
.acf-relationship .filters .filter input:focus, .acf-relationship .filters .filter input:active,
.acf-relationship .filters .filter select:focus,
.acf-relationship .filters .filter select:active {
  outline: none;
  box-shadow: none;
}
.acf-relationship .filters .filter input {
  border-color: transparent;
  box-shadow: none;
  padding-left: 3px;
  padding-right: 3px;
}
.acf-relationship .filters.-f2 .filter {
  width: 50%;
}
.acf-relationship .filters.-f3 .filter {
  width: 25%;
}
.acf-relationship .filters.-f3 .filter.-search {
  width: 50%;
}
.acf-relationship .list {
  margin: 0;
  padding: 5px;
  height: 160px;
  overflow: auto;
}
.acf-relationship .list .acf-rel-label,
.acf-relationship .list .acf-rel-item,
.acf-relationship .list p {
  padding: 5px;
  margin: 0;
  display: block;
  position: relative;
  min-height: 18px;
}
.acf-relationship .list .acf-rel-label {
  font-weight: bold;
}
.acf-relationship .list .acf-rel-item {
  cursor: pointer;
  /* hover */
  /* disabled */
}
.acf-relationship .list .acf-rel-item b {
  text-decoration: underline;
  font-weight: normal;
}
.acf-relationship .list .acf-rel-item .thumbnail {
  background: #e0e0e0;
  width: 22px;
  height: 22px;
  float: left;
  margin: -2px 5px 0 0;
}
.acf-relationship .list .acf-rel-item .thumbnail img {
  max-width: 22px;
  max-height: 22px;
  margin: 0 auto;
  display: block;
}
.acf-relationship .list .acf-rel-item .thumbnail.-icon {
  background: #fff;
}
.acf-relationship .list .acf-rel-item .thumbnail.-icon img {
  max-height: 20px;
  margin-top: 1px;
}
.acf-relationship .list .acf-rel-item:hover {
  background: #3875d7;
  color: #fff;
}
.acf-relationship .list .acf-rel-item:hover .thumbnail {
  background: #a2bfec;
}
.acf-relationship .list .acf-rel-item:hover .thumbnail.-icon {
  background: #fff;
}
.acf-relationship .list .acf-rel-item.disabled {
  opacity: 0.5;
}
.acf-relationship .list .acf-rel-item.disabled:hover {
  background: transparent;
  color: #333;
  cursor: default;
}
.acf-relationship .list .acf-rel-item.disabled:hover .thumbnail {
  background: #e0e0e0;
}
.acf-relationship .list .acf-rel-item.disabled:hover .thumbnail.-icon {
  background: #fff;
}
.acf-relationship .list ul {
  padding-bottom: 5px;
}
.acf-relationship .list ul .acf-rel-label,
.acf-relationship .list ul .acf-rel-item,
.acf-relationship .list ul p {
  padding-left: 20px;
}
.acf-relationship .selection {
  position: relative;
  /* choices */
  /* values */
}
.acf-relationship .selection:after {
  display: block;
  clear: both;
  content: "";
}
.acf-relationship .selection .values,
.acf-relationship .selection .choices {
  width: 50%;
  background: #fff;
  float: left;
}
.acf-relationship .selection .choices {
  background: #f9f9f9;
}
.acf-relationship .selection .choices .list {
  border-right: #dfdfdf solid 1px;
}
.acf-relationship .selection .values .acf-icon {
  position: absolute;
  top: 4px;
  right: 7px;
  display: none;
  /* rtl */
}
html[dir=rtl] .acf-relationship .selection .values .acf-icon {
  right: auto;
  left: 7px;
}
.acf-relationship .selection .values .acf-rel-item:hover .acf-icon {
  display: block;
}
.acf-relationship .selection .values .acf-rel-item {
  cursor: move;
}
.acf-relationship .selection .values .acf-rel-item b {
  text-decoration: none;
}

/* menu item fix */
.menu-item .acf-relationship ul {
  width: auto;
}
.menu-item .acf-relationship li {
  display: block;
}

/*--------------------------------------------------------------------------
*
*	WYSIWYG
*
*-------------------------------------------------------------------------*/
.acf-editor-wrap.delay .acf-editor-toolbar {
  content: "";
  display: block;
  background: #f5f5f5;
  border-bottom: #dddddd solid 1px;
  color: #555d66;
  padding: 10px;
}
.acf-editor-wrap.delay .wp-editor-area {
  padding: 10px;
  border: none;
  color: inherit !important;
}
.acf-editor-wrap iframe {
  min-height: 200px;
}
.acf-editor-wrap .wp-editor-container {
  border: 1px solid #ccd0d4;
  box-shadow: none !important;
}
.acf-editor-wrap .wp-editor-tabs {
  box-sizing: content-box;
}
.acf-editor-wrap .wp-switch-editor {
  border-color: #ccd0d4;
  border-bottom-color: transparent;
}

#mce_fullscreen_container {
  z-index: 900000 !important;
}

/*-----------------------------------------------------------------------------
*
*	Tab
*
*-----------------------------------------------------------------------------*/
.acf-field-tab {
  display: none !important;
}

.hidden-by-tab {
  display: none !important;
}

.acf-tab-wrap {
  clear: both;
  z-index: 1;
}

.acf-tab-group {
  border-bottom: #ccc solid 1px;
  padding: 10px 10px 0;
}
.acf-tab-group li {
  margin: 0 0.5em 0 0;
}
.acf-tab-group li a {
  padding: 5px 10px;
  display: block;
  color: #555;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border: #ccc solid 1px;
  border-bottom: 0 none;
  text-decoration: none;
  background: #e5e5e5;
  transition: none;
}
.acf-tab-group li a:hover {
  background: #fff;
}
.acf-tab-group li a:focus {
  outline: none;
  box-shadow: none;
}
.acf-tab-group li a:empty {
  display: none;
}
html[dir=rtl] .acf-tab-group li {
  margin: 0 0 0 0.5em;
}
.acf-tab-group li.active a {
  background: #f1f1f1;
  color: #000;
  padding-bottom: 6px;
  margin-bottom: -1px;
  position: relative;
  z-index: 1;
}

.acf-fields > .acf-tab-wrap {
  background: #f9f9f9;
}
.acf-fields > .acf-tab-wrap .acf-tab-group {
  position: relative;
  border-top: #ccd0d4 solid 1px;
  border-bottom: #ccd0d4 solid 1px;
  z-index: 2;
  margin-bottom: -1px;
}
.acf-admin-3-8 .acf-fields > .acf-tab-wrap .acf-tab-group {
  border-color: #dfdfdf;
}

.acf-fields.-left > .acf-tab-wrap .acf-tab-group {
  padding-left: 20%;
  /* mobile */
  /* rtl */
}
@media screen and (max-width: 640px) {
  .acf-fields.-left > .acf-tab-wrap .acf-tab-group {
    padding-left: 10px;
  }
}
html[dir=rtl] .acf-fields.-left > .acf-tab-wrap .acf-tab-group {
  padding-left: 0;
  padding-right: 20%;
  /* mobile */
}
@media screen and (max-width: 850px) {
  html[dir=rtl] .acf-fields.-left > .acf-tab-wrap .acf-tab-group {
    padding-right: 10px;
  }
}

.acf-tab-wrap.-left .acf-tab-group {
  position: absolute;
  left: 0;
  width: 20%;
  border: 0 none;
  padding: 0 !important; /* important overrides 'left aligned labels' */
  margin: 1px 0 0;
}
.acf-tab-wrap.-left .acf-tab-group li {
  float: none;
  margin: -1px 0 0;
}
.acf-tab-wrap.-left .acf-tab-group li a {
  border: 1px solid #ededed;
  font-size: 13px;
  line-height: 18px;
  color: #0073aa;
  padding: 10px;
  margin: 0;
  font-weight: normal;
  border-width: 1px 0;
  border-radius: 0;
  background: transparent;
}
.acf-tab-wrap.-left .acf-tab-group li a:hover {
  color: #00a0d2;
}
.acf-tab-wrap.-left .acf-tab-group li.active a {
  border-color: #dfdfdf;
  color: #000;
  margin-right: -1px;
  background: #fff;
}
html[dir=rtl] .acf-tab-wrap.-left .acf-tab-group {
  left: auto;
  right: 0;
}
html[dir=rtl] .acf-tab-wrap.-left .acf-tab-group li.active a {
  margin-right: 0;
  margin-left: -1px;
}
.acf-field + .acf-tab-wrap.-left:before {
  content: "";
  display: block;
  position: relative;
  z-index: 1;
  height: 10px;
  border-top: #dfdfdf solid 1px;
  border-bottom: #dfdfdf solid 1px;
  margin-bottom: -1px;
}
.acf-tab-wrap.-left:first-child .acf-tab-group li:first-child a {
  border-top: none;
}

/* sidebar */
.acf-fields.-sidebar {
  padding: 0 0 0 20% !important;
  position: relative;
  /* before */
  /* rtl */
}
.acf-fields.-sidebar:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  bottom: 0;
  border-right: #dfdfdf solid 1px;
  background: #f9f9f9;
  z-index: 1;
}
html[dir=rtl] .acf-fields.-sidebar {
  padding: 0 20% 0 0 !important;
}
html[dir=rtl] .acf-fields.-sidebar:before {
  border-left: #dfdfdf solid 1px;
  border-right-width: 0;
  left: auto;
  right: 0;
}
.acf-fields.-sidebar.-left {
  padding: 0 0 0 180px !important;
  /* rtl */
}
html[dir=rtl] .acf-fields.-sidebar.-left {
  padding: 0 180px 0 0 !important;
}
.acf-fields.-sidebar.-left:before {
  background: #f1f1f1;
  border-color: #dfdfdf;
  width: 180px;
}
.acf-fields.-sidebar.-left > .acf-tab-wrap.-left .acf-tab-group {
  width: 180px;
}
.acf-fields.-sidebar.-left > .acf-tab-wrap.-left .acf-tab-group li a {
  border-color: #e4e4e4;
}
.acf-fields.-sidebar.-left > .acf-tab-wrap.-left .acf-tab-group li.active a {
  background: #f9f9f9;
}
.acf-fields.-sidebar > .acf-field-tab + .acf-field {
  border-top: none;
}

.acf-fields.-clear > .acf-tab-wrap {
  background: transparent;
}
.acf-fields.-clear > .acf-tab-wrap .acf-tab-group {
  margin-top: 0;
  border-top: none;
  padding-left: 0;
  padding-right: 0;
}
.acf-fields.-clear > .acf-tab-wrap .acf-tab-group li a {
  background: #e5e5e5;
}
.acf-fields.-clear > .acf-tab-wrap .acf-tab-group li a:hover {
  background: #fff;
}
.acf-fields.-clear > .acf-tab-wrap .acf-tab-group li.active a {
  background: #f1f1f1;
}

/* seamless */
.acf-postbox.seamless > .acf-fields.-sidebar {
  margin-left: 0 !important;
}
.acf-postbox.seamless > .acf-fields.-sidebar:before {
  background: transparent;
}
.acf-postbox.seamless > .acf-fields > .acf-tab-wrap {
  background: transparent;
  margin-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
}
.acf-postbox.seamless > .acf-fields > .acf-tab-wrap .acf-tab-group {
  border-top: 0 none;
  border-color: #ccd0d4;
}
.acf-postbox.seamless > .acf-fields > .acf-tab-wrap .acf-tab-group li a {
  background: #e5e5e5;
  border-color: #ccd0d4;
}
.acf-postbox.seamless > .acf-fields > .acf-tab-wrap .acf-tab-group li a:hover {
  background: #fff;
}
.acf-postbox.seamless > .acf-fields > .acf-tab-wrap .acf-tab-group li.active a {
  background: #f1f1f1;
}
.acf-postbox.seamless > .acf-fields > .acf-tab-wrap.-left:before {
  border-top: none;
  height: auto;
}
.acf-postbox.seamless > .acf-fields > .acf-tab-wrap.-left .acf-tab-group {
  margin-bottom: 0;
}
.acf-postbox.seamless > .acf-fields > .acf-tab-wrap.-left .acf-tab-group li a {
  border-width: 1px 0 1px 1px !important;
  border-color: #cccccc;
  background: #e5e5e5;
}
.acf-postbox.seamless > .acf-fields > .acf-tab-wrap.-left .acf-tab-group li.active a {
  background: #f1f1f1;
}

.menu-edit .acf-fields.-clear > .acf-tab-wrap .acf-tab-group li a,
.widget .acf-fields.-clear > .acf-tab-wrap .acf-tab-group li a {
  background: #f1f1f1;
}
.menu-edit .acf-fields.-clear > .acf-tab-wrap .acf-tab-group li a:hover, .menu-edit .acf-fields.-clear > .acf-tab-wrap .acf-tab-group li.active a,
.widget .acf-fields.-clear > .acf-tab-wrap .acf-tab-group li a:hover,
.widget .acf-fields.-clear > .acf-tab-wrap .acf-tab-group li.active a {
  background: #fff;
}

.compat-item .acf-tab-wrap td {
  display: block;
}

/* within gallery sidebar */
.acf-gallery-side .acf-tab-wrap {
  border-top: 0 none !important;
}

.acf-gallery-side .acf-tab-wrap .acf-tab-group {
  margin: 10px 0 !important;
  padding: 0 !important;
}

.acf-gallery-side .acf-tab-group li.active a {
  background: #f9f9f9 !important;
}

/* withing widget */
.widget .acf-tab-group {
  border-bottom-color: #e8e8e8;
}

.widget .acf-tab-group li a {
  background: #f1f1f1;
}

.widget .acf-tab-group li.active a {
  background: #fff;
}

/* media popup (edit image) */
.media-modal.acf-expanded .compat-attachment-fields > tbody > tr.acf-tab-wrap .acf-tab-group {
  padding-left: 23%;
  border-bottom-color: #dddddd;
}

/* table */
.form-table > tbody > tr.acf-tab-wrap .acf-tab-group {
  padding: 0 5px 0 210px;
}

/* rtl */
html[dir=rtl] .form-table > tbody > tr.acf-tab-wrap .acf-tab-group {
  padding: 0 210px 0 5px;
}

/*--------------------------------------------------------------------------------------------
*
*	oembed
*
*--------------------------------------------------------------------------------------------*/
.acf-oembed {
  position: relative;
  border: #ccd0d4 solid 1px;
  background: #fff;
}
.acf-oembed .title {
  position: relative;
  border-bottom: #ccd0d4 solid 1px;
  padding: 5px 10px;
}
.acf-oembed .title .input-search {
  margin: 0;
  font-size: 14px;
  line-height: 30px;
  height: 30px;
  padding: 0;
  border: 0 none;
  box-shadow: none;
  border-radius: 0;
  font-family: inherit;
  cursor: text;
}
.acf-oembed .title .acf-actions {
  padding: 6px;
}
.acf-oembed .canvas {
  position: relative;
  min-height: 250px;
  background: #f9f9f9;
}
.acf-oembed .canvas .canvas-media {
  position: relative;
  z-index: 1;
}
.acf-oembed .canvas iframe {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}
.acf-oembed .canvas .acf-icon.-picture {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  height: 42px;
  width: 42px;
  font-size: 42px;
  color: #999;
}
.acf-oembed .canvas .acf-loading-overlay {
  background: rgba(255, 255, 255, 0.9);
}
.acf-oembed .canvas .canvas-error {
  position: absolute;
  top: 50%;
  left: 0%;
  right: 0%;
  margin: -9px 0 0 0;
  text-align: center;
  display: none;
}
.acf-oembed .canvas .canvas-error p {
  padding: 8px;
  margin: 0;
  display: inline;
}
.acf-oembed.has-value .canvas {
  min-height: 50px;
}
.acf-oembed.has-value .input-search {
  font-weight: bold;
}
.acf-oembed.has-value .title:hover .acf-actions {
  display: block;
}

/*--------------------------------------------------------------------------------------------
*
*	Image
*
*--------------------------------------------------------------------------------------------*/
.acf-image-uploader {
  position: relative;
  /* image wrap*/
  /* input */
  /* rtl */
}
.acf-image-uploader:after {
  display: block;
  clear: both;
  content: "";
}
.acf-image-uploader p {
  margin: 0;
}
.acf-image-uploader .image-wrap {
  position: relative;
  float: left;
  /* hover */
}
.acf-image-uploader .image-wrap img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: block;
  min-width: 30px;
  min-height: 30px;
  background: #f1f1f1;
  margin: 0;
  padding: 0;
  /* svg */
}
.acf-image-uploader .image-wrap img[src$=".svg"] {
  min-height: 100px;
  min-width: 100px;
}
.acf-image-uploader .image-wrap:hover .acf-actions {
  display: block;
}
.acf-image-uploader input.button {
  width: auto;
}
html[dir=rtl] .acf-image-uploader .image-wrap {
  float: right;
}

/*--------------------------------------------------------------------------------------------
*
*	File
*
*--------------------------------------------------------------------------------------------*/
.acf-file-uploader {
  position: relative;
  /* hover */
  /* rtl */
}
.acf-file-uploader p {
  margin: 0;
}
.acf-file-uploader .file-wrap {
  border: #ccd0d4 solid 1px;
  min-height: 84px;
  position: relative;
  background: #fff;
}
.acf-file-uploader .file-icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 10px;
  background: #f1f1f1;
  border-right: #d5d9dd solid 1px;
}
.acf-file-uploader .file-icon img {
  display: block;
  padding: 0;
  margin: 0;
  max-width: 48px;
}
.acf-file-uploader .file-info {
  padding: 10px;
  margin-left: 69px;
}
.acf-file-uploader .file-info p {
  margin: 0 0 2px;
  font-size: 13px;
  line-height: 1.4em;
  word-break: break-all;
}
.acf-file-uploader .file-info a {
  text-decoration: none;
}
.acf-file-uploader:hover .acf-actions {
  display: block;
}
html[dir=rtl] .acf-file-uploader .file-icon {
  left: auto;
  right: 0;
  border-left: #e5e5e5 solid 1px;
  border-right: none;
}
html[dir=rtl] .acf-file-uploader .file-info {
  margin-right: 69px;
  margin-left: 0;
}

/*-----------------------------------------------------------------------------
*
*	Date Picker
*
*-----------------------------------------------------------------------------*/
.acf-ui-datepicker .ui-datepicker {
  z-index: 900000 !important;
}
.acf-ui-datepicker .ui-datepicker .ui-widget-header a {
  cursor: pointer;
  transition: none;
}

/* fix highlight state overriding hover / active */
.acf-ui-datepicker .ui-state-highlight.ui-state-hover {
  border: 1px solid #98b7e8 !important;
  background: #98b7e8 !important;
  font-weight: normal !important;
  color: #ffffff !important;
}

.acf-ui-datepicker .ui-state-highlight.ui-state-active {
  border: 1px solid #3875d7 !important;
  background: #3875d7 !important;
  font-weight: normal !important;
  color: #ffffff !important;
}

/*-----------------------------------------------------------------------------
*
*	Separator field
*
*-----------------------------------------------------------------------------*/
.acf-field-separator {
  /* fields */
}
.acf-field-separator .acf-label {
  margin-bottom: 0;
}
.acf-field-separator .acf-label label {
  font-weight: normal;
}
.acf-field-separator .acf-input {
  display: none;
}
.acf-fields > .acf-field-separator {
  background: #f9f9f9;
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
  margin-bottom: -1px;
  z-index: 2;
}

/*-----------------------------------------------------------------------------
*
*	Taxonomy
*
*-----------------------------------------------------------------------------*/
.acf-taxonomy-field {
  position: relative;
  /* hover */
  /* select */
}
.acf-taxonomy-field .categorychecklist-holder {
  border: #ccd0d4 solid 1px;
  border-radius: 3px;
  max-height: 200px;
  overflow: auto;
}
.acf-taxonomy-field .acf-checkbox-list {
  margin: 0;
  padding: 10px;
}
.acf-taxonomy-field .acf-checkbox-list ul.children {
  padding-left: 18px;
}
.acf-taxonomy-field:hover .acf-actions {
  display: block;
}
.acf-taxonomy-field[data-ftype=select] .acf-actions {
  padding: 0;
  margin: -9px;
}

/*-----------------------------------------------------------------------------
*
*	Range
*
*-----------------------------------------------------------------------------*/
.acf-range-wrap {
  /* rtl */
}
.acf-range-wrap .acf-append,
.acf-range-wrap .acf-prepend {
  display: inline-block;
  vertical-align: middle;
  line-height: 28px;
  margin: 0 7px 0 0;
}
.acf-range-wrap .acf-append {
  margin: 0 0 0 7px;
}
.acf-range-wrap input[type=range] {
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  height: 28px;
}
.acf-range-wrap input[type=range]:focus {
  outline: none;
}
.acf-range-wrap input[type=number] {
  display: inline-block;
  min-width: 5em;
  padding-right: 4px;
  margin-left: 10px;
  vertical-align: middle;
}
html[dir=rtl] .acf-range-wrap input[type=number] {
  margin-right: 10px;
  margin-left: 0;
}
html[dir=rtl] .acf-range-wrap .acf-append {
  margin: 0 7px 0 0;
}
html[dir=rtl] .acf-range-wrap .acf-prepend {
  margin: 0 0 0 7px;
}

/*-----------------------------------------------------------------------------
*
*  acf-accordion
*
*-----------------------------------------------------------------------------*/
.acf-accordion {
  margin: -1px 0;
  padding: 0;
  background: #fff;
  border-top: 1px solid #d5d9dd;
  border-bottom: 1px solid #d5d9dd;
  z-index: 1;
}
.acf-accordion .acf-accordion-title {
  margin: 0;
  padding: 12px;
  font-weight: bold;
  cursor: pointer;
  font-size: inherit;
  font-size: 13px;
  line-height: 1.4em;
}
.acf-accordion .acf-accordion-title:hover {
  background: #f3f4f5;
}
.acf-accordion .acf-accordion-title label {
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 1.4em;
}
.acf-accordion .acf-accordion-title p {
  font-weight: normal;
}
.acf-accordion .acf-accordion-title .acf-accordion-icon {
  float: right;
}
.acf-accordion .acf-accordion-title svg.acf-accordion-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #191e23;
  fill: currentColor;
}
.acf-accordion .acf-accordion-content {
  margin: 0;
  padding: 0 12px 12px;
  display: none;
}
.acf-accordion.-open > .acf-accordion-content {
  display: block;
}

.acf-field.acf-accordion {
  margin: -1px 0;
  padding: 0 !important;
  border-color: #d5d9dd;
}
.acf-field.acf-accordion .acf-label.acf-accordion-title {
  padding: 12px;
  width: auto;
  float: none;
  width: auto;
}
.acf-field.acf-accordion .acf-input.acf-accordion-content {
  padding: 0;
  float: none;
  width: auto;
}
.acf-field.acf-accordion .acf-input.acf-accordion-content > .acf-fields {
  border-top: #eeeeee solid 1px;
}
.acf-field.acf-accordion .acf-input.acf-accordion-content > .acf-fields.-clear {
  padding: 0 12px 15px;
}

/* field specific (left) */
.acf-fields.-left > .acf-field.acf-accordion:before {
  display: none;
}
.acf-fields.-left > .acf-field.acf-accordion .acf-accordion-title {
  width: auto;
  margin: 0 !important;
  padding: 12px;
  float: none !important;
}
.acf-fields.-left > .acf-field.acf-accordion .acf-accordion-content {
  padding: 0 !important;
}

/* field specific (clear) */
.acf-fields.-clear > .acf-field.acf-accordion {
  border: #cccccc solid 1px;
  background: transparent;
}
.acf-fields.-clear > .acf-field.acf-accordion + .acf-field.acf-accordion {
  margin-top: -16px;
}

/* table */
tr.acf-field.acf-accordion {
  background: transparent;
}
tr.acf-field.acf-accordion > .acf-input {
  padding: 0 !important;
  border: #cccccc solid 1px;
}
tr.acf-field.acf-accordion .acf-accordion-content {
  padding: 0 12px 12px;
}

/* #addtag */
#addtag div.acf-field.error {
  border: 0 none;
  padding: 8px 0;
}

#addtag > .acf-field.acf-accordion {
  padding-right: 0;
  margin-right: 5%;
}
#addtag > .acf-field.acf-accordion + p.submit {
  margin-top: 0;
}

/* border */
tr.acf-accordion {
  margin: 15px 0 !important;
}
tr.acf-accordion + tr.acf-accordion {
  margin-top: -16px !important;
}

/* seamless */
.acf-postbox.seamless > .acf-fields > .acf-accordion {
  margin-left: 12px;
  margin-right: 12px;
  border: #ccd0d4 solid 1px;
}

/* rtl */
/* menu item */
/*
.menu-item-settings > .field-acf > .acf-field.acf-accordion {
	border: #dfdfdf solid 1px;
	margin: 10px -13px 10px -11px;

	+ .acf-field.acf-accordion {
		margin-top: -11px;
	}
}
*/
/* widget */
.widget .widget-content > .acf-field.acf-accordion {
  border: #dfdfdf solid 1px;
  margin-bottom: 10px;
}
.widget .widget-content > .acf-field.acf-accordion .acf-accordion-title {
  margin-bottom: 0;
}
.widget .widget-content > .acf-field.acf-accordion + .acf-field.acf-accordion {
  margin-top: -11px;
}

.media-modal .compat-attachment-fields .acf-field.acf-accordion + .acf-field.acf-accordion {
  margin-top: -1px;
}
.media-modal .compat-attachment-fields .acf-field.acf-accordion > .acf-input {
  width: 100%;
}
.media-modal .compat-attachment-fields .acf-field.acf-accordion .compat-attachment-fields > tbody > tr > td {
  padding-bottom: 5px;
}

/*-----------------------------------------------------------------------------
*
*	Block Editor
*
*-----------------------------------------------------------------------------*/
.block-editor .edit-post-sidebar .acf-postbox > .postbox-header,
.block-editor .edit-post-sidebar .acf-postbox > .hndle {
  border-bottom-width: 0 !important;
}
.block-editor .edit-post-sidebar .acf-postbox.closed > .postbox-header,
.block-editor .edit-post-sidebar .acf-postbox.closed > .hndle {
  border-bottom-width: 1px !important;
}
.block-editor .edit-post-sidebar .acf-fields {
  min-height: 1px;
  overflow: auto;
}
.block-editor .edit-post-sidebar .acf-fields > .acf-field {
  border-width: 0;
  border-color: #e2e4e7;
  margin: 16px;
  padding: 0;
  width: auto !important;
  min-height: 0 !important;
  float: none !important;
}
.block-editor .edit-post-sidebar .acf-fields > .acf-field > .acf-label {
  margin-bottom: 5px;
}
.block-editor .edit-post-sidebar .acf-fields > .acf-field > .acf-label label {
  font-weight: normal;
}
.block-editor .edit-post-sidebar .acf-fields > .acf-field.acf-accordion {
  padding: 0;
  margin: 0;
  border-top-width: 1px;
}
.block-editor .edit-post-sidebar .acf-fields > .acf-field.acf-accordion:first-child {
  border-top-width: 0;
}
.block-editor .edit-post-sidebar .acf-fields > .acf-field.acf-accordion .acf-accordion-title {
  margin: 0;
  padding: 15px;
}
.block-editor .edit-post-sidebar .acf-fields > .acf-field.acf-accordion .acf-accordion-title label {
  font-weight: 500;
  color: rgb(30, 30, 30);
}
.block-editor .edit-post-sidebar .acf-fields > .acf-field.acf-accordion .acf-accordion-title svg.acf-accordion-icon {
  right: 16px;
}
.block-editor .edit-post-sidebar .acf-fields > .acf-field.acf-accordion .acf-accordion-content > .acf-fields {
  border-top-width: 0;
}

/*-----------------------------------------------------------------------------
*
*  Prefix field label & prefix field names
*
*-----------------------------------------------------------------------------*/
.acf-field-setting-prefix_label p.description,
.acf-field-setting-prefix_name p.description {
  order: 3;
  margin-top: 0;
  margin-left: 16px;
}
.acf-field-setting-prefix_label p.description code,
.acf-field-setting-prefix_name p.description code {
  padding-top: 4px;
  padding-right: 6px;
  padding-bottom: 4px;
  padding-left: 6px;
  background-color: #F2F4F7;
  border-radius: 4px;
  color: #667085;
}

/*-----------------------------------------------------------------------------
*
*  Editor tab styles
*
*-----------------------------------------------------------------------------*/
.acf-fields > .acf-tab-wrap:first-child .acf-tab-group {
  border-top: none;
}

.acf-fields > .acf-tab-wrap .acf-tab-group li.active a {
  background: #ffffff;
}

.acf-fields > .acf-tab-wrap .acf-tab-group li a {
  background: #f1f1f1;
  border-color: #ccd0d4;
}

.acf-fields > .acf-tab-wrap .acf-tab-group li a:hover {
  background: #fff;
}

/*--------------------------------------------------------------------------------------------
*
*	User
*
*--------------------------------------------------------------------------------------------*/
.form-table > tbody {
  /* field */
  /* tab wrap */
  /* misc */
}
.form-table > tbody > .acf-field {
  /* label */
  /* input */
}
.form-table > tbody > .acf-field > .acf-label {
  padding: 20px 10px 20px 0;
  width: 210px;
  /* rtl */
}
html[dir=rtl] .form-table > tbody > .acf-field > .acf-label {
  padding: 20px 0 20px 10px;
}
.form-table > tbody > .acf-field > .acf-label label {
  font-size: 14px;
  color: #23282d;
}
.form-table > tbody > .acf-field > .acf-input {
  padding: 15px 10px;
  /* rtl */
}
html[dir=rtl] .form-table > tbody > .acf-field > .acf-input {
  padding: 15px 10px 15px 5%;
}
.form-table > tbody > .acf-tab-wrap td {
  padding: 15px 5% 15px 0;
  /* rtl */
}
html[dir=rtl] .form-table > tbody > .acf-tab-wrap td {
  padding: 15px 0 15px 5%;
}
.form-table > tbody .form-table th.acf-th {
  width: auto;
}

#your-profile,
#createuser {
  /* override for user css */
  /* allow sub fields to display correctly */
}
#your-profile .acf-field input[type=text],
#your-profile .acf-field input[type=password],
#your-profile .acf-field input[type=number],
#your-profile .acf-field input[type=search],
#your-profile .acf-field input[type=email],
#your-profile .acf-field input[type=url],
#your-profile .acf-field select,
#createuser .acf-field input[type=text],
#createuser .acf-field input[type=password],
#createuser .acf-field input[type=number],
#createuser .acf-field input[type=search],
#createuser .acf-field input[type=email],
#createuser .acf-field input[type=url],
#createuser .acf-field select {
  max-width: 25em;
}
#your-profile .acf-field textarea,
#createuser .acf-field textarea {
  max-width: 500px;
}
#your-profile .acf-field .acf-field input[type=text],
#your-profile .acf-field .acf-field input[type=password],
#your-profile .acf-field .acf-field input[type=number],
#your-profile .acf-field .acf-field input[type=search],
#your-profile .acf-field .acf-field input[type=email],
#your-profile .acf-field .acf-field input[type=url],
#your-profile .acf-field .acf-field textarea,
#your-profile .acf-field .acf-field select,
#createuser .acf-field .acf-field input[type=text],
#createuser .acf-field .acf-field input[type=password],
#createuser .acf-field .acf-field input[type=number],
#createuser .acf-field .acf-field input[type=search],
#createuser .acf-field .acf-field input[type=email],
#createuser .acf-field .acf-field input[type=url],
#createuser .acf-field .acf-field textarea,
#createuser .acf-field .acf-field select {
  max-width: none;
}

#registerform h2 {
  margin: 1em 0;
}
#registerform .acf-field {
  margin-top: 0;
  /*
  		.acf-input {
  			input {
  				font-size: 24px;
  				padding: 5px;
  				height: auto;
  			}
  		}
  */
}
#registerform .acf-field .acf-label {
  margin-bottom: 0;
}
#registerform .acf-field .acf-label label {
  font-weight: normal;
  line-height: 1.5;
}
#registerform p.submit {
  text-align: right;
}

/*--------------------------------------------------------------------------------------------
*
*	Term
*
*--------------------------------------------------------------------------------------------*/
#acf-term-fields {
  padding-right: 5%;
}
#acf-term-fields > .acf-field > .acf-label {
  margin: 0;
}
#acf-term-fields > .acf-field > .acf-label label {
  font-size: 12px;
  font-weight: normal;
}

p.submit .spinner,
p.submit .acf-spinner {
  vertical-align: top;
  float: none;
  margin: 4px 4px 0;
}

#edittag .acf-fields.-left > .acf-field {
  padding-left: 220px;
}
#edittag .acf-fields.-left > .acf-field:before {
  width: 209px;
}
#edittag .acf-fields.-left > .acf-field > .acf-label {
  width: 220px;
  margin-left: -220px;
  padding: 0 10px;
}
#edittag .acf-fields.-left > .acf-field > .acf-input {
  padding: 0;
}

#edittag > .acf-fields.-left {
  width: 96%;
}
#edittag > .acf-fields.-left > .acf-field > .acf-label {
  padding-left: 0;
}

/*--------------------------------------------------------------------------------------------
*
*	Comment
*
*--------------------------------------------------------------------------------------------*/
.editcomment td:first-child {
  white-space: nowrap;
  width: 131px;
}

/*--------------------------------------------------------------------------------------------
*
*	Widget
*
*--------------------------------------------------------------------------------------------*/
#widgets-right .widget .acf-field .description {
  padding-left: 0;
  padding-right: 0;
}

.acf-widget-fields > .acf-field .acf-label {
  margin-bottom: 5px;
}
.acf-widget-fields > .acf-field .acf-label label {
  font-weight: normal;
  margin: 0;
}

/*--------------------------------------------------------------------------------------------
*
*	Nav Menu
*
*--------------------------------------------------------------------------------------------*/
.acf-menu-settings {
  border-top: 1px solid #eee;
  margin-top: 2em;
}
.acf-menu-settings.-seamless {
  border-top: none;
  margin-top: 15px;
}
.acf-menu-settings.-seamless > h2 {
  display: none;
}
.acf-menu-settings .list li {
  display: block;
  margin-bottom: 0;
}

.acf-fields.acf-menu-item-fields {
  clear: both;
  padding-top: 1px;
}
.acf-fields.acf-menu-item-fields > .acf-field {
  margin: 5px 0;
  padding-right: 10px;
}
.acf-fields.acf-menu-item-fields > .acf-field .acf-label {
  margin-bottom: 0;
}
.acf-fields.acf-menu-item-fields > .acf-field .acf-label label {
  font-style: italic;
  font-weight: normal;
}

/*---------------------------------------------------------------------------------------------
*
*  Attachment Form (single)
*
*---------------------------------------------------------------------------------------------*/
#post .compat-attachment-fields .compat-field-acf-form-data {
  display: none;
}
#post .compat-attachment-fields,
#post .compat-attachment-fields > tbody,
#post .compat-attachment-fields > tbody > tr,
#post .compat-attachment-fields > tbody > tr > th,
#post .compat-attachment-fields > tbody > tr > td {
  display: block;
}
#post .compat-attachment-fields > tbody > .acf-field {
  margin: 15px 0;
}
#post .compat-attachment-fields > tbody > .acf-field > .acf-label {
  margin: 0;
}
#post .compat-attachment-fields > tbody > .acf-field > .acf-label label {
  margin: 0;
  padding: 0;
}
#post .compat-attachment-fields > tbody > .acf-field > .acf-label label p {
  margin: 0 0 3px !important;
}
#post .compat-attachment-fields > tbody > .acf-field > .acf-input {
  margin: 0;
}

/*---------------------------------------------------------------------------------------------
*
*  Media Model
*
*---------------------------------------------------------------------------------------------*/
/* WP sets tables to act as divs. ACF uses tables, so these muct be reset */
.media-modal .compat-attachment-fields td.acf-input table {
  display: table;
  table-layout: auto;
}
.media-modal .compat-attachment-fields td.acf-input table tbody {
  display: table-row-group;
}
.media-modal .compat-attachment-fields td.acf-input table tr {
  display: table-row;
}
.media-modal .compat-attachment-fields td.acf-input table td, .media-modal .compat-attachment-fields td.acf-input table th {
  display: table-cell;
}

/* field widths floats */
.media-modal .compat-attachment-fields > tbody > .acf-field {
  margin: 5px 0;
}
.media-modal .compat-attachment-fields > tbody > .acf-field > .acf-label {
  min-width: 30%;
  margin: 0;
  padding: 0;
  float: left;
  text-align: right;
  display: block;
  float: left;
}
.media-modal .compat-attachment-fields > tbody > .acf-field > .acf-label > label {
  padding-top: 6px;
  margin: 0;
  color: #666666;
  font-weight: 400;
  line-height: 16px;
}
.media-modal .compat-attachment-fields > tbody > .acf-field > .acf-input {
  width: 65%;
  margin: 0;
  padding: 0;
  float: right;
  display: block;
}
.media-modal .compat-attachment-fields > tbody > .acf-field p.description {
  margin: 0;
}

/* restricted selection (copy of WP .upload-errors)*/
.acf-selection-error {
  background: #ffebe8;
  border: 1px solid #c00;
  border-radius: 3px;
  padding: 8px;
  margin: 20px 0 0;
}
.acf-selection-error .selection-error-label {
  background: #CC0000;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  margin-right: 8px;
  padding: 2px 4px;
}
.acf-selection-error .selection-error-message {
  color: #b44;
  display: block;
  padding-top: 8px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

/* disabled attachment */
.media-modal .attachment.acf-disabled .thumbnail {
  opacity: 0.25 !important;
}
.media-modal .attachment.acf-disabled .attachment-preview:before {
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  position: relative;
}

/* misc */
.media-modal {
  /* compat-item */
  /* allow line breaks in upload error */
  /* fix required span */
  /* sidebar */
  /* mobile md */
}
.media-modal .compat-field-acf-form-data,
.media-modal .compat-field-acf-blank {
  display: none !important;
}
.media-modal .upload-error-message {
  white-space: pre-wrap;
}
.media-modal .acf-required {
  padding: 0 !important;
  margin: 0 !important;
  float: none !important;
  color: #f00 !important;
}
.media-modal .media-sidebar .compat-item {
  padding-bottom: 20px;
}
@media (max-width: 900px) {
  .media-modal {
    /* label */
    /* field */
  }
  .media-modal .setting span,
  .media-modal .compat-attachment-fields > tbody > .acf-field > .acf-label {
    width: 98%;
    float: none;
    text-align: left;
    min-height: 0;
    padding: 0;
  }
  .media-modal .setting input,
  .media-modal .setting textarea,
  .media-modal .compat-attachment-fields > tbody > .acf-field > .acf-input {
    float: none;
    height: auto;
    max-width: none;
    width: 98%;
  }
}

/*---------------------------------------------------------------------------------------------
*
*  Media Model (expand details)
*
*---------------------------------------------------------------------------------------------*/
.media-modal .acf-expand-details {
  float: right;
  padding: 8px 10px;
  margin-right: 6px;
  font-size: 13px;
  height: 18px;
  line-height: 18px;
  color: #666;
  text-decoration: none;
}
.media-modal .acf-expand-details:focus, .media-modal .acf-expand-details:active {
  outline: 0 none;
  box-shadow: none;
  color: #666;
}
.media-modal .acf-expand-details:hover {
  color: #000;
}
.media-modal .acf-expand-details .is-open {
  display: none;
}
.media-modal .acf-expand-details .is-closed {
  display: block;
}
@media (max-width: 640px) {
  .media-modal .acf-expand-details {
    display: none;
  }
}

/* expanded */
.media-modal.acf-expanded {
  /* toggle */
}
.media-modal.acf-expanded .acf-expand-details .is-open {
  display: block;
}
.media-modal.acf-expanded .acf-expand-details .is-closed {
  display: none;
}
.media-modal.acf-expanded .attachments-browser .media-toolbar,
.media-modal.acf-expanded .attachments-browser .attachments {
  right: 740px;
}
.media-modal.acf-expanded .media-sidebar {
  width: 708px;
}
.media-modal.acf-expanded .media-sidebar .attachment-info .thumbnail {
  float: left;
  max-height: none;
}
.media-modal.acf-expanded .media-sidebar .attachment-info .thumbnail img {
  max-width: 100%;
  max-height: 200px;
}
.media-modal.acf-expanded .media-sidebar .attachment-info .details {
  float: right;
}
.media-modal.acf-expanded .media-sidebar .attachment-info .thumbnail,
.media-modal.acf-expanded .media-sidebar .attachment-details .setting .name,
.media-modal.acf-expanded .media-sidebar .compat-attachment-fields > tbody > .acf-field > .acf-label {
  min-width: 20%;
  margin-right: 0;
}
.media-modal.acf-expanded .media-sidebar .attachment-info .details,
.media-modal.acf-expanded .media-sidebar .attachment-details .setting input,
.media-modal.acf-expanded .media-sidebar .attachment-details .setting textarea,
.media-modal.acf-expanded .media-sidebar .attachment-details .setting + .description,
.media-modal.acf-expanded .media-sidebar .compat-attachment-fields > tbody > .acf-field > .acf-input {
  min-width: 77%;
}
@media (max-width: 900px) {
  .media-modal.acf-expanded .attachments-browser .media-toolbar {
    display: none;
  }
  .media-modal.acf-expanded .attachments {
    display: none;
  }
  .media-modal.acf-expanded .media-sidebar {
    width: auto;
    max-width: none !important;
    bottom: 0 !important;
  }
  .media-modal.acf-expanded .media-sidebar .attachment-info .thumbnail {
    min-width: 0;
    max-width: none;
    width: 30%;
  }
  .media-modal.acf-expanded .media-sidebar .attachment-info .details {
    min-width: 0;
    max-width: none;
    width: 67%;
  }
}
@media (max-width: 640px) {
  .media-modal.acf-expanded .media-sidebar .attachment-info .thumbnail, .media-modal.acf-expanded .media-sidebar .attachment-info .details {
    width: 100%;
  }
}

/*---------------------------------------------------------------------------------------------
*
*  ACF Media Model
*
*---------------------------------------------------------------------------------------------*/
.acf-media-modal {
  /* hide embed settings */
}
.acf-media-modal .media-embed .setting.align,
.acf-media-modal .media-embed .setting.link-to {
  display: none;
}

/*---------------------------------------------------------------------------------------------
*
*  ACF Media Model (Select Mode)
*
*---------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------
*
*  ACF Media Model (Edit Mode)
*
*---------------------------------------------------------------------------------------------*/
.acf-media-modal.-edit {
  /* resize modal */
  left: 15%;
  right: 15%;
  top: 100px;
  bottom: 100px;
  /* hide elements */
  /* full width */
  /* tidy up incorrect distance */
  /* title box shadow (to match media grid) */
  /* sidebar */
  /* mobile md */
  /* mobile sm */
}
.acf-media-modal.-edit .media-frame-menu,
.acf-media-modal.-edit .media-frame-router,
.acf-media-modal.-edit .media-frame-content .attachments,
.acf-media-modal.-edit .media-frame-content .media-toolbar {
  display: none;
}
.acf-media-modal.-edit .media-frame-title,
.acf-media-modal.-edit .media-frame-content,
.acf-media-modal.-edit .media-frame-toolbar,
.acf-media-modal.-edit .media-sidebar {
  width: auto;
  left: 0;
  right: 0;
}
.acf-media-modal.-edit .media-frame-content {
  top: 50px;
}
.acf-media-modal.-edit .media-frame-title {
  border-bottom: 1px solid #DFDFDF;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.1);
}
.acf-media-modal.-edit .media-sidebar {
  padding: 0 16px;
  /* WP details */
  /* ACF fields */
  /* WP required message */
}
.acf-media-modal.-edit .media-sidebar .attachment-details {
  overflow: visible;
  /* hide 'Attachment Details' heading */
  /* remove overflow */
  /* move thumbnail */
}
.acf-media-modal.-edit .media-sidebar .attachment-details > h3, .acf-media-modal.-edit .media-sidebar .attachment-details > h2 {
  display: none;
}
.acf-media-modal.-edit .media-sidebar .attachment-details .attachment-info {
  background: #fff;
  border-bottom: #dddddd solid 1px;
  padding: 16px;
  margin: 0 -16px 16px;
}
.acf-media-modal.-edit .media-sidebar .attachment-details .thumbnail {
  margin: 0 16px 0 0;
}
.acf-media-modal.-edit .media-sidebar .attachment-details .setting {
  margin: 0 0 5px;
}
.acf-media-modal.-edit .media-sidebar .attachment-details .setting span {
  margin: 0;
}
.acf-media-modal.-edit .media-sidebar .compat-attachment-fields > tbody > .acf-field {
  margin: 0 0 5px;
}
.acf-media-modal.-edit .media-sidebar .compat-attachment-fields > tbody > .acf-field p.description {
  margin-top: 3px;
}
.acf-media-modal.-edit .media-sidebar .media-types-required-info {
  display: none;
}
@media (max-width: 900px) {
  .acf-media-modal.-edit {
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
  }
}
@media (max-width: 640px) {
  .acf-media-modal.-edit {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media (max-width: 480px) {
  .acf-media-modal.-edit .media-frame-content {
    top: 40px;
  }
}

.acf-temp-remove {
  position: relative;
  opacity: 1;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  overflow: hidden;
  /* overlay prevents hover */
}
.acf-temp-remove:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}

.hidden-by-conditional-logic {
  display: none !important;
}
.hidden-by-conditional-logic.appear-empty {
  display: table-cell !important;
}
.hidden-by-conditional-logic.appear-empty .acf-input {
  display: none !important;
}

.acf-postbox.acf-hidden {
  display: none !important;
}

.acf-attention {
  transition: border 0.25s ease-out;
}
.acf-attention.-focused {
  border: #23282d solid 1px !important;
  transition: none;
}

tr.acf-attention {
  transition: box-shadow 0.25s ease-out;
  position: relative;
}
tr.acf-attention.-focused {
  box-shadow: #23282d 0 0 0px 1px !important;
}

#editor .edit-post-layout__metaboxes {
  padding: 0;
}
#editor .edit-post-layout__metaboxes .edit-post-meta-boxes-area {
  margin: 0;
}
#editor .metabox-location-side .postbox-container {
  float: none;
}
#editor .postbox {
  color: #444;
}
#editor .postbox > .postbox-header .hndle {
  border-bottom: none;
}
#editor .postbox > .postbox-header .hndle:hover {
  background: transparent;
}
#editor .postbox > .postbox-header .handle-actions .handle-order-higher,
#editor .postbox > .postbox-header .handle-actions .handle-order-lower {
  width: 1.62rem;
}
#editor .postbox > .postbox-header .handle-actions .acf-hndle-cog {
  height: 44px;
  line-height: 44px;
}
#editor .postbox > .postbox-header:hover {
  background: #f0f0f0;
}
#editor .postbox:last-child.closed > .postbox-header {
  border-bottom: none;
}
#editor .postbox:last-child > .inside {
  border-bottom: none;
}
#editor .block-editor-writing-flow__click-redirect {
  min-height: 50px;
}

body.is-dragging-metaboxes #acf_after_title-sortables {
  outline: 3px dashed #646970;
  display: flow-root;
  min-height: 60px;
  margin-bottom: 3px !important;
}